<mat-toolbar>
  <mat-toolbar-row *ngIf="loadUpdate">
    <div class="col-md-4" style="padding-left: 0px;">
      <div
        (click)="loadNewUpdate()"
        style="background: hsl(36, 100%, 50%);display: inline-flex;padding: 2%;cursor: pointer;width: 230px;"
      >
        <div>
          <mat-icon style="color:#FFFFFF;font-size: 32px;padding-right: 41px;"
            >autorenew</mat-icon
          >
        </div>
        <div>
          <h1 style="color:#FFFFFF;font-size: 15px;line-height: normal;">
            {{ "navLinks.newVersion" | translate }}
          </h1>
          <h1 style="color:#FFFFFF;font-size: 12px;line-height: normal;">
            {{ "navLinks.launchVersion" | translate }}
          </h1>
        </div>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row
    class="tool-bar-flex"
    style="max-width: 1300px;margin: 0 auto;"
  >
    <button
      aria-labelledby="menu"
      mat-flat-button
      (click)="toggleSideNav()"
      *ngIf="handsetMode"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <a routerLink="/" class="navbar-logo" (click)="goTo($event, '/')">
      <img
        [title]="siilaBrandSEO.title"
        [alt]="siilaBrandSEO.title"
        [src]="siilaBrandSEO.src"
        class="brand-style"
      />
    </a>
    <div *ngIf="handsetMode" style="display: flex;margin-left: 10px;">
      <latam-nav-menu
        [ngClass]="{ 'margin-latam': currentCode === 'latam' }"
      ></latam-nav-menu>
      <app-language-menu *ngIf="currentCode != 'latam'"></app-language-menu>
    </div>

    <div
      *ngIf="!handsetMode"
      class="nav-link-container container-size container-stretch"
      [ngClass]="{ 'font-size-14': screenWidth > 1000 && screenWidth < 1401 }"
    >
      <span
        mat-flat-button
        *ngFor="let element of menuElements"
        mat-button
        [matMenuTriggerFor]="menu"
        class="menu-item"
      >
        <a
          *ngIf="!handsetMode"
          [translate]="element.text"
          class="blue-link mat-h3"
        ></a>
        <mat-icon>{{ element.icon }}</mat-icon>
        <mat-menu #menu="matMenu">
          <a class="btn-decoration" routerLink="/products">
            <button mat-menu-item class="mat-sub-menu">
              <mat-icon class="icon-style">important_devices</mat-icon>
              {{ "navLinks.allProducts" | translate }}
            </button>
          </a>

          <a class="btn-decoration" routerLink="/marketanalytics">
            <button mat-menu-item class="mat-sub-menu">
              <mat-icon class="icon-style">bar_chart</mat-icon>
              MARKET ANALYTICS
            </button>
          </a>
          <div *ngIf="currentCode != 'co'">
            <a
              *ngFor="let item of checkId"
              [routerLink]="[
                '/products',
                item.id,
                item.title | langSelector: true
              ]"
              class="btn-decoration"
            >
              <button class="mat-sub-menu" mat-menu-item>
                <mat-icon class="icon-style">{{ item.icon }}</mat-icon>
                {{ item.title | langSelector }}
              </button>
            </a>

            <a class="btn-decoration" routerLink="/SPOT">
              <button mat-menu-item class="mat-sub-menu">
                <mat-icon class="icon-style">location_pin</mat-icon>
                SiiLA SPOT
              </button>
            </a>
            <a class="btn-decoration" routerLink="/academy">
              <button mat-menu-item class="mat-sub-menu">
                <mat-icon class="icon-style">school</mat-icon>
                ACADEMY
              </button>
            </a>
            <a
              *ngIf="currentCode != 'latam'"
              class="btn-decoration"
              [routerLink]="
                currentCode == 'mx' ? '/fibra-analytics' : '/fii-data-pro'
              "
            >
              <button mat-menu-item class="mat-sub-menu">
                <mat-icon class="icon-style">corporate_fare</mat-icon>
                {{
                  (currentCode == "mx"
                    ? "navLinks.fiiDataMx"
                    : "navLinks.fiiData"
                  ) | translate
                }}
              </button>
            </a>
          </div>

          <a
            *ngIf="currentCode == 'latam'"
            class="btn-decoration"
            [routerLink]="'/fii-data-pro'"
          >
            <button mat-menu-item class="mat-sub-menu">
              <mat-icon class="icon-style">corporate_fare</mat-icon>
              {{ "navLinks.fiiData" | translate }}
            </button>
          </a>
          <a
            *ngIf="currentCode == 'latam'"
            class="btn-decoration"
            [routerLink]="'/fibra-analytics'"
          >
            <button mat-menu-item class="mat-sub-menu">
              <mat-icon class="icon-style">corporate_fare</mat-icon>
              {{ "navLinks.fiiDataMx" | translate }}
            </button>
          </a>
        </mat-menu>
      </span>

      <span class="menu-item" mat-button [matMenuTriggerFor]="menu">
        <a
          class="blue-link mat-h3"
          *ngIf="!handsetMode"
          translate="navLinks.solutions"
        ></a>
        <mat-icon>arrow_drop_down</mat-icon>
        <mat-menu #menu="matMenu">
          <a
            *ngFor="let link of solutions"
            [routerLink]="[
              '/solutions',
              link.id,
              link.solutionName | langSelector: true
            ]"
            class="btn-decoration"
          >
            <button mat-menu-item class="mat-sub-menu uppercase">
              {{ link.shortName | langSelector }}
            </button>
          </a>
        </mat-menu>
      </span>

      <!--  <span class="menu-item">
        <a
          class="blue-link mat-h3"
          *ngIf="!handsetMode"
          target="_blank"
          routerLink="/blog"
          translate="navLinks.blog"
          (click)="goTo($event, '/blog')"
        ></a>
      </span> -->

      <span class="menu-item" mat-button [matMenuTriggerFor]="menui">
        <a class="btn-decoration blue-link mat-h3" *ngIf="!handsetMode"
          >REsource</a
        >
        <mat-icon>arrow_drop_down</mat-icon>
        <mat-menu #menui="matMenu">
          <a
            *ngIf="currentCode != 'co'"
            routerLink="/resource"
            class="btn-decoration"
          >
            <button mat-menu-item class="mat-sub-menu">
              <img
                class="m-2"
                width="24"
                src="../../../../assets/icons/library_books_FILL0_wght400_GRAD0_opsz48.svg"
                alt=""
              />
              <span style="text-transform: uppercase;">{{
                "news.resource" | translate
              }}</span>
            </button>
          </a>
          <a
            *ngIf="currentCode == 'co'"
            (click)="openResource('mx')"
            class="btn-decoration"
          >
            <button mat-menu-item class="mat-sub-menu">
              <img
                class="m-2"
                width="24"
                src="../../../../assets/icons/library_books_FILL0_wght400_GRAD0_opsz48.svg"
                alt=""
              />
              <span style="text-transform: uppercase;">{{
                "news.resourceMx" | translate
              }}</span>
            </button>
          </a>
          <a
            *ngIf="currentCode == 'co'"
            (click)="openResource('br')"
            class="btn-decoration"
          >
            <button mat-menu-item class="mat-sub-menu">
              <img
                class="m-2"
                width="24"
                src="../../../../assets/icons/library_books_FILL0_wght400_GRAD0_opsz48.svg"
                alt=""
              />
              <span style="text-transform: uppercase;">{{
                "news.resourceBr" | translate
              }}</span>
            </button>
          </a>
          <a *ngIf="currentCode != 'co'" class="btn-decoration">
            <button (click)="openMedia()" mat-menu-item class="mat-sub-menu">
              <img
                class="m-2"
                width="24"
                src="../../../../assets/icons/play_circle_FILL0_wght400_GRAD0_opsz48.svg"
                alt=""
              /><span style="text-transform: uppercase;">{{
                "news.media" | translate
              }}</span>
            </button>
          </a>
        </mat-menu>
      </span>

      <span
        id="companyMenu"
        class="menu-item"
        mat-button
        [matMenuTriggerFor]="companyopt"
      >
        <a class="btn-decoration blue-link mat-h3" *ngIf="!handsetMode">{{
          "navLinks.company" | translate
        }}</a>
        <mat-icon>arrow_drop_down</mat-icon>
        <mat-menu #companyopt="matMenu">
          <a routerLink="/about" class="btn-decoration">
            <button mat-menu-item class="mat-sub-menu">
              {{ "navLinks.aboutUs" | translate }}
            </button>
          </a>
          <a routerLink="/about" fragment="leadership" class="btn-decoration">
            <button mat-menu-item class="mat-sub-menu">
              {{ "navLinks.leadership" | translate }}
            </button>
          </a>
          <a class="btn-decoration">
            <button mat-menu-item class="mat-sub-menu" (click)="openPress()">
              {{ "navLinks.press" | translate }}
            </button>
          </a>
        </mat-menu>
      </span>

      <span
        style="width: 50px;"
        class="menu-item"
        [ngClass]="{ 'font-size-14': screenWidth > 1000 && screenWidth < 1401 }"
      >
        <latam-nav-menu [handsetMode]="handsetMode"></latam-nav-menu>
      </span>
      <span
        style="width: 80px;"
        class="menu-item"
        [ngClass]="{ 'font-size-14': screenWidth > 1000 && screenWidth < 1401 }"
      >
        <app-language-menu *ngIf="!handsetMode"></app-language-menu>
      </span>

      <div
        [ngClass]="{
          'latam-logout-login': ($isLoggedIn | async) && !handsetMode
        }"
        class="buttons-menu"
      >
        <div *ngIf="handsetMode">
          <div
            *ngIf="loadUpdate"
            (click)="loadNewUpdate()"
            style="background: hsl(36, 100%, 50%);
                  display: inline-flex;
                  padding: 5%;
                  cursor: pointer;"
          >
            <div>
              <mat-icon
                style="color:#FFFFFF;font-size: 28px;padding-right: 30px;"
                >autorenew</mat-icon
              >
            </div>
            <div>
              <h1 style="color:#FFFFFF;font-size: 11px;line-height: normal;">
                {{ "navLinks.newVersion" | translate }}
              </h1>
              <h1 style="color:#FFFFFF;font-size: 9px;line-height: normal;">
                {{ "navLinks.launchVersion" | translate }}
              </h1>
            </div>
          </div>
        </div>

        <span class="menu-item" *ngIf="($isLoggedIn | async) && !handsetMode">
          <button
            mat-flat-button
            color="accent"
            *ngIf="!handsetMode"
            (click)="login()"
            translate="navLinks.home"
          ></button>
        </span>
        <span
          class="menu-item"
          *ngIf="
            (this.countryButton == 'BR' || this.countryButton == 'LATAM') &&
            !($isLoggedIn | async)
          "
        >
          <a
            class="btn-decoration blue-link mat-h3"
            *ngIf="!handsetMode"
            (click)="openEmail()"
            translate="navLinks.contact"
          ></a>
        </span>
        <span
          class="menu-item"
          *ngIf="this.countryButton == 'MX' && !($isLoggedIn | async)"
        >
          <a
            class="btn-decoration blue-link mat-h3"
            *ngIf="!handsetMode"
            (click)="openEmailMX()"
            translate="navLinks.contact"
          ></a>
        </span>
        <!--  <div *ngIf="$isLoggedIn | async" class="mat-h3" style="display: flex;">
          <img
            [src]="photoUser"
            alt=""
            style="width: 36px;
          height: 36px;
          border-radius: 3em;
          margin-right: 8px;"
          />
          <span style="margin-top: 6px;">
            {{ user?.firstName }} {{ user?.lastName }}
          </span>
        </div> -->

        <div *ngIf="this.countryButton !== 'LATAM'">
          <button
            mat-stroked-button
            color="primary"
            class="removeFocused"
            aria-label="login"
            *ngIf="!($isLoggedIn | async) && !handsetMode"
            (click)="login()"
            id="login"
          >
            {{ "LOG IN" | translate }}
          </button>
        </div>

        <button
          class="removeFocused"
          aria-label="demo"
          *ngIf="!($isLoggedIn | async) && !handsetMode"
          (click)="openDemoDialog()"
          mat-flat-button
          color="accent"
        >
          {{ "demo-menu" | translate }}
        </button>

        <span
          *ngIf="($isLoggedIn | async) && !handsetMode"
          id="companyMenu"
          class="menu-item"
          mat-button
          [matMenuTriggerFor]="login"
          style="margin-right: 38px;"
        >
          <a class="btn-decoration blue-link mat-h3" *ngIf="!handsetMode"
            ><img
              [src]="photoUser"
              alt=""
              style="width: 36px;
        height: 36px;
        border-radius: 3em;
        margin-right: 8px;"
            />
            <span style="margin-top: 6px;">
              {{ user?.firstName }} {{ user?.lastName }}
            </span></a
          >
          <mat-icon>arrow_drop_down</mat-icon>
          <mat-menu #login="matMenu">
            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('PROPERTIES')"
              >
                {{ "properties" | translate }}
              </button>
            </a>
            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('transactions')"
              >
                {{ "transactions" | translate }}
              </button>
            </a>
            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('companies')"
              >
                {{ "companies" | translate }}
              </button>
            </a>
            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('capitalMarkets')"
              >
                {{ "capitalMarkets" | translate }}
              </button>
            </a>
            <a *ngIf="countryButton == 'BR'" class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('fII')"
              >
                {{ "fII" | translate }}
              </button>
            </a>
            <a *ngIf="countryButton == 'MX'" class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('fibra')"
              >
                {{ "fibra" | translate }}
              </button>
            </a>
            <a *ngIf="isBR()" class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('sbi')"
              >
                {{ "sbi" | translate }}
              </button>
            </a>
            <a *ngIf="isMX()" class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('sbi')"
              >
                {{ "smi" | translate }}
              </button>
            </a>
            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('marketReports')"
              >
                {{ "marketReports" | translate }}
              </button>
            </a>
            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('marketStats')"
              >
                {{ "marketStats" | translate }}
              </button>
            </a>
            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('TENANTSTATS')"
              >
                {{ "tenantStats" | translate }}
              </button>
            </a>
            <a *ngIf="isBR()" class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('GROCS')"
              >
                {{ "grocsTxt" | translate }}
              </button>
            </a>
            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                (click)="navigateTo('MYACCOUNT')"
              >
                {{ "myAccount" | translate }}
              </button>
            </a>
            <a class="btn-decoration" *ngIf="isMX() || isBR()">
              <button mat-menu-item [matMenuTriggerFor]="admin">
                {{ "global.menu.admin.admin" | translate }}
              </button>
            </a>
            <mat-menu #admin="matMenu">
              <a class="btn-decoration">
                <button
                  (click)="openMenuOption('company')"
                  mat-menu-item
                  class="mat-sub-menu"
                >
                  {{ "companyMenu" | translate }}
                </button>
              </a>
              <a class="btn-decoration">
                <button
                  (click)="openMenuOption('users')"
                  mat-menu-item
                  class="mat-sub-menu"
                >
                  {{ "registerUser" | translate }}
                </button>
              </a>
              <a class="btn-decoration">
                <button
                  (click)="openMenuOption('corporations-with-portfolios')"
                  mat-menu-item
                  class="mat-sub-menu"
                >
                  {{ "companiesPortfolios" | translate }}
                </button>
              </a>
              <a class="btn-decoration">
                <button
                  (click)="openMenuOption('realEstateFund')"
                  mat-menu-item
                  class="mat-sub-menu"
                >
                  {{ "realEstateFunds" | translate }}
                </button>
              </a>
              <a class="btn-decoration">
                <button
                  (click)="openQuarterly()"
                  mat-menu-item
                  class="mat-sub-menu"
                >
                  {{ "quarterlyStatsBooking" | translate }}
                </button>
              </a>
            </mat-menu>

            <a class="btn-decoration">
              <button
                mat-menu-item
                class="mat-sub-menu"
                style="color: red;"
                (click)="logout()"
              >
                {{ "logout" | translate }}
              </button>
            </a>
          </mat-menu>
        </span>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
