import {
  Component,
  HostBinding,
  Input,
  OnInit,
  EventEmitter,
  Output,
  HostListener,
  AfterViewInit,
  NgZone
} from "@angular/core";
import { WindowRef } from "../../../window-ref.service";
import { Notifications } from "../../../core/services/notifications.service";
import { Observable, of, ReplaySubject, Subject, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { delay, map, take } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import {
  LoginDialogService,
  DemoDialogService,
  ContactDialogService
} from "../../../core/services/dialog";
import { AuthenticationService } from "../../../core/auth/authentication.service";
import { DemoDialogComponent } from "../dialogs/demo-dialog/demo-dialog.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ForgotDialogService } from "../../../core/services/dialog/forgot-dialog.service";
import { ForgotDialogComponent } from "../dialogs/forgot-dialog/forgot-dialog.component";
import { NewFeaturesDialogComponent } from "../new-features-banner/new-features-dialog.component";
import { NewFeaturesService } from "../../../core/services/new-features.service";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { ProductService } from "../../../core/services/products.service";
import {
  MenuItemSmallLogo,
  ProductItem,
  SolutionMenuItem
} from "../../../core/models/products.model";
import { title } from "process";
import { StaticImagesSEOService } from "../../../core/services/static-images.service";
import { ImageSEO } from "../../../core/models/image-seo.model";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { I18nService } from "../../services";
import { InstallPromptComponent } from "../dialogs";
import { CaptchaService } from "../../services/captcha.service";

@Component({
  selector: "menu",
  templateUrl: "menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements OnInit, AfterViewInit {
  checkId = [];
  products: ProductItem[];
  countryButton: string;
  @Input("contextual")
  @Output()
  toggleSideMenu = new EventEmitter();
  @HostBinding("class.contextual")
  public isSafari: boolean = false;
  public subscribeText: Subject<string> = new ReplaySubject();
  public menuElements: MenuElement[] = [
    { link: "/products", icon: "arrow_drop_down", text: "navLinks.products" }
  ];

  public sideNavToggled: boolean = false;
  public contextual: boolean = false;
  public $isLoggedIn: Observable<boolean>;
  private _isRegistered: boolean;
  public handsetMode: boolean = false;
  public $displayingBanner: Observable<boolean>;
  public mediumScreenSize: boolean = false;
  public screenWidth: any;

  public loadUpdate: boolean = false;
  public solutions: SolutionMenuItem[];
  currentCode: string;
  siilaBrandSEO: ImageSEO;
  browserLang: string;
  user;
  photoUser = "";
  isAdmin = false;

  constructor(
    private productService: ProductService,
    private ns: Notifications,
    private window: WindowRef,
    private dialog: LoginDialogService,
    private matDialog: MatDialog,
    private auth: AuthenticationService,
    private breakpointObserver: BreakpointObserver,
    private demoDialog: DemoDialogService,
    private forgotDialog: ForgotDialogService,
    private newFeaturesService: NewFeaturesService,
    private windowRef: WindowRef,
    private router: Router,
    private swUpdate: SwUpdate,
    private staticImgSrv: StaticImagesSEOService,
    private siilaTranslationService: SiilaTranslationService,
    private captchaService: CaptchaService
  ) {
    this.currentCode = environment.platformId;
    this.$isLoggedIn = this.auth.$isLoginSubject;
    this.screenWidth = window.nativeWindow.innerWidth;
    this.screenWidth < 1001
      ? (this.handsetMode = true)
      : (this.handsetMode = false);
    this.breakpointObserver.observe([Breakpoints.Medium]).subscribe(result => {
      this.mediumScreenSize = result.matches;
    });
    this.countryButton = environment.platformId.toUpperCase();
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
  }

  ngAfterViewInit(): void {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isAdmin = user?.roles?.includes("ROLE_ADMIN");
    let element;
    let validateStyle;
    if (document.getElementById("login")) {
      element = document.getElementById("login");
      validateStyle = getComputedStyle(element);

      if (validateStyle?.getPropertyValue("border-color") === "rgb(0, 0, 0)") {
        window.location.reload();
      }
    }

    if (document.getElementById("latamNav")) {
      element = document.getElementById("latamNav");
      validateStyle = getComputedStyle(element);

      if (
        validateStyle?.getPropertyValue("background-color") ===
        "rgb(240, 240, 240)"
      ) {
        window.location.reload();
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    event.target.innerWidth < 1001
      ? (this.handsetMode = true)
      : (this.handsetMode = false);
  }

  public ngOnInit(): void {
    this.$isLoggedIn.subscribe(change => {
      if (change) {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.photoUser = localStorage.getItem("photoUser");
      }
    });

    this.siilaBrandSEO = this.staticImgSrv.getSiilaBrandSEOInfo();
    this.productService.getSolutionList().subscribe(solutions => {
      this.solutions = solutions;
    });

    this.productService.getMarketAnalytics().subscribe(products => {
      this.products = products;
      this.checkId = products.map(item => {
        return {
          id: item.id,
          title: item.productShortName,
          icon: item.iconCode
        };
      });
    });

    this.isSafari = !!this.window.nativeWindow["safari"];
    this.ns.isSubscribed().subscribe((registered: boolean) => {
      registered
        ? this.subscribeText.next("Unsubscribe from push")
        : this.subscribeText.next("Subscribe to push");
      this._isRegistered = registered;
    });
    //this.checkInitialNewFeaturesTrigger();
  }

  checkInitialNewFeaturesTrigger() {
    this.newFeaturesService.showNewFeaturesItem$
      .pipe(delay(0), take(1))
      .subscribe(val => {
        if (val && !this.handsetMode) this.openNewFeatures();
      });
  }

  public isRegistrationAvailable(): Observable<boolean> {
    if (this.isSafari) {
      return this.ns.isSubscribed().pipe(map(registered => !registered));
    } else if (this.ns.isPushAvailable()) {
      return of(true);
    } else {
      return of(false);
    }
  }

  public toggleSubscription(): void {
    if (this._isRegistered) {
      this.ns.unsubscribeFromPush().subscribe();
    } else {
      this.ns.subscribeToPush().subscribe();
    }
  }

  public navigateToLegacy() {
    window.open(`${environment.redirectURL}`, "_self");
  }

  public navigateTo(menu: string) {
    var legacyURL;

    switch (menu) {
      case "PROPERTIES":
        legacyURL = "buildings/search";
        break;
      case "transactions":
        legacyURL = "transactions";
        break;
      case "companies":
        legacyURL = "companySearch";
        break;
      case "capitalMarkets":
        legacyURL = "caprates";
        break;
      case "fII":
        legacyURL = "funds";
        break;
      case "fibra":
        legacyURL = "funds-dashboard";
        break;
      case "sbi":
        legacyURL = "siila-index";
        break;
      case "marketReports":
        legacyURL = "market-report";
        break;
      case "marketStats":
        legacyURL = "market-statistics";
        break;
      case "TENANTSTATS":
        legacyURL = "tenant-statistics";
        break;
      case "GROCS":
        legacyURL = "grocs";
        break;
      case "MYACCOUNT":
        legacyURL = "profile";
        break;
    }

    if (legacyURL) {
      window.open(`${environment.redirectURL}${legacyURL}`, "_self");
    }
  }

  public login() {
    this.windowRef.nativeWindow.open(
      `${environment.redirectURL}lang=${this.browserLang}`,
      "_self"
    );
  }

  public logout() {
    this.auth.logout();
  }

  public toggleSideNav() {
    this.validateUpdate();

    this.sideNavToggled = !this.sideNavToggled;
    this.toggleSideMenu.emit(this.sideNavToggled);
  }

  public openDemoDialog() {
    this.demoDialog.open(DemoDialogComponent);
  }

  public openForgotDialog() {
    this.forgotDialog.openDialog(ForgotDialogComponent);
  }

  public installSpot() {
    if (!environment.spotURL.includes("localhost")) {
      window.open(environment.spotURL);
    }
  }

  public async openNewFeatures() {
    const dialogRef = this.matDialog.open(NewFeaturesDialogComponent, {
      width: "800px",
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      this.newFeaturesService.setLastNewFeaturesUpdate();
    });
  }

  goTo(e: Event, url: string) {
    e.preventDefault();

    this.validateUpdate();

    if (url == "/blog") {
      window.open(url, "_blank");
    } else {
      this.router.navigateByUrl(url);
    }
  }

  public loadNewUpdate() {
    if (localStorage.getItem("token")) {
      this.auth.removeCredentials();
    }
    this.windowRef.nativeWindow.location.reload();
  }

  public async validateUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(evt => {
        this.loadUpdate = true;
      });
    }

    this.swUpdate
      .checkForUpdate()
      .then(() => {})
      .catch(err => {
        console.error("error when checking for update", err);
      });
  }

  openEmail() {
    window.location.href =
      this.countryButton == "BR"
        ? `mailto:contato@siila.com.br`
        : `mailto:contact@siila.com`;
    return false;
  }

  openEmailMX() {
    window.location.href = `mailto:contacto@siila.com.mx`;
    return false;
  }

  openMedia() {
    if (
      this.currentCode == "mx" ||
      this.currentCode == "latam" ||
      this.currentCode == "co"
    ) {
      window.open(
        `https://www.youtube.com/channel/UCTm4KXeOEGPEyCNyCHH_Jgw`,
        this.handsetMode ? "_self" : "_blank"
      );
    } else {
      window.open(
        `https://www.youtube.com/c/SiiLABR`,
        this.handsetMode ? "_self" : "_blank"
      );
    }
  }

  openPress() {
    window.open(
      `https://siila.com/press/lang/en`,
      this.handsetMode ? "_self" : "_blank"
    );
  }

  isMX() {
    return this.countryButton == "MX";
  }

  isBR() {
    return this.countryButton == "BR";
  }

  openResource(country) {
    if (country == "mx") {
      window.open(
        `${environment.applicationURLMx}/resource/lang/${this.browserLang}`,
        this.handsetMode ? "_self" : "_blank"
      );
    } else {
      window.open(
        `${environment.applicationURLBr}/resource/lang/en`,
        this.handsetMode ? "_self" : "_blank"
      );
    }
  }

  openMenuOption(option) {
    window.open(`${environment.serverURL}/#!/${option}`, "blank");
  }

  openQuarterly() {
    window.open(
      `${
        this.isMX()
          ? environment.applicationURLMx
          : environment.applicationURLBr
      }/tenant-stats/market-config/lang/en`,
      "blank"
    );
  }
}

interface MenuElement {
  link: string;
  icon: string;
  text: string;
}
